<template>
	<div class="information">
		<Top @fatherMethod="hang"></Top>
		<div class="banners">
			<img src="../assets/image/banner.png">
		</div>
		<div class="information-content">
			<div class="information-content-title">
				<div class="tab-categoryName">
					<span>职位：</span>
					<ul class="tab-categoryName-content">
						<li v-for="(i,j) in categories" :key="j" @click="zhi(j,i.categoryId)" :class="{te:zhiValue===j}">
							{{i.categoryName}}
						</li>
					</ul>
				</div>
				<div class="tab-categoryName">
					<span>薪资：</span>
					<ul class="tab-categoryName-content">
						<li v-for="(i,j) in options" :key="j" @click="xin(i.value)" :class="{te:xinValue===j}">
							{{i.label}}
						</li>
					</ul>
				</div>
			</div>
			<div class="information-content-main">
				<div class="content-main-item" v-for="(item,index) in xunniuPostLists" :key="index"
					@click="homePost(item.postId)">
					<div class="content-main-item-left">
						<div class="item-informate">
							<span>{{item.postTitle}}</span>
							<div class="item-tag">
								<div class="item-tag-item">
									<img src="../assets/image/tag1.png">
									<span>{{item.eduRequire}}</span>
								</div>
								<div class="item-tag-item2">
									<img src="../assets/image/tag2.png">
									<span>{{item.expRequire}}</span>
								</div>
							</div>
							<div class="item-informate-di">
								<img src="../assets/image/di.png">
								<span>
									{{pcaaProvince(item.province)}} {{pcaaCity(item.province,item.city)}}
								</span>
							</div>
						</div>
						<div class="item-xin">
							<span>{{price(item.salaryType)}} 元/月</span>
						</div>
					</div>
					<div class="line">
						<div class="line-main">

						</div>
					</div>
					<div class="content-main-item-right">
						<div class="item-right-header">
							<img v-lazy="item.pubUser.headImg">
						</div>
						<div class="item-right-main">
							<div class="item-right-main-top">
								{{item.pubUser.nickName}}・<span>{{item.pubUser.position}}</span>
							</div>
							<div class="item-right-main-bottom">
								<span style="margin-right: .05rem;">{{item.pubUser.companyName}}</span>
								<span>{{item.pubUser.companyScale}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="fenye">
					<el-pagination background @current-change="current" :current-page="page+1"
						layout="total, prev, pager, next, jumper" :total="total*10">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from './common/Top.vue'
	import {
		pcaa
	} from 'area-data';
	import $http from '../common/api/axios.js'
	import {
		recruitmentInfo,industryInfo
	} from '../common/api/api.js'
	import Footer from '../components/common/Footer.vue'
	export default {
		data() {
			return {
				currcet: '',
				zhiValue: '',
				xinValue: '',
				options: [{
						value: -1,
						label: '全部'
					},
					{
						value: 0,
						label: '0-5000'
					}, {
						value: 1,
						label: '5000-8000'
					}, {
						value: 2,
						label: '8000-11000'
					}, {
						value: 3,
						label: '11000-15000'
					}, {
						value: 4,
						label: '15000-20000'
					}, {
						value: 5,
						label: '20000-30000'
					}, {
						value: 6,
						label: '30000-50000'
					},
				],
				value: 1,
				value2: '',
				value3: '',
				industryInfos: [],
				categories: [],
				xunniuPostLists: [],
				total: 0,
				minWage: '',
				maxWage: '',
				page: 0,

			}
		},
		components: {
			Top,
			Footer
		},
		created() {
			let that = this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: industryInfo,
				}).then((res) => {
					that.industryInfos = res.data.industryInfos
					console.log(this.$route.query.value)
					console.log(this.$route.query.currcet)
					this.value=this.$route.query.value
					this.currcet=this.$route.query.currcet
					this.categories = this.industryInfos[this.currcet].categories
				}).catch(() => {
					
				})
			})
		},
		mounted() {
			this.$store.dispatch('initDic').then(() => {
				let that = this
				$http.request({
					url: recruitmentInfo,
					data: {
						"category": that.value2,
						"industry": that.value,
						"maxWage": that.maxWage,
						"minWage": that.minWage,
						"startPage": that.page
					}
				}).then((res) => {
					that.xunniuPostLists = res.data.xunniuPostLists
					that.total = res.data.pageNum
				}).catch(() => {
				
				})
			})
		},
		methods: {
			openFullScreen2() {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				setTimeout(() => {
					loading.close();
				}, 500);
			},
			homePost(postId) {
				let id = postId
				this.$router.push({
					path: '/pj-details',
					query: {
						id: id
					}
				})
			},
			price(salaryType) {
				let xinoptions = this.$store.state.xinoptions;
				return xinoptions[salaryType].label
			},
			pcaaProvince(province) {
				let num1 = 86
				let num2 = province
				return pcaa[num1][num2]
			},
			pcaaCity(province, city) {
				let num1 = province
				let num2 = city
				return pcaa[num1][num2]

			},
			//请求
			request() {
				let that = this
				$http.request({
					url: recruitmentInfo,
					data: {
						"category": that.value2,
						"industry": that.value,
						"maxWage": that.maxWage,
						"minWage": that.minWage,
						"startPage": that.page
					}
				}).then((res) => {
					this.openFullScreen2()
					that.xunniuPostLists = res.data.xunniuPostLists
					that.total = res.data.pageNum
				}).catch(() => {

				})

			},
			xin(e) {				
				this.xinValue = e+1			
				if(e==-1){
					this.minWage=''
					this.maxWage=''
				}else{
					let string = this.options. [this.xinValue].label
					this.minWage = string.split('-')[0];
					this.maxWage = string.split('-')[1] == "以上" ? '' : string.split('-')[1]
				}
				this.request()
			},
			hang(e) {
				this.value = e.value
				this.currcet = e.currcet
				this.value2 = ''
				this.zhiValue = ''
				this.page = 0
				let num = this.currcet
				this.categories = this.industryInfos[num].categories
				this.request()
			},
			zhi(j, id) {
				if (this.value == '') {
					this.$message({
						message: '请先选择行业!',
						type: 'warning',
						duration:'2000'
					});
				} else {
					this.page = 0
					this.value2 = id
					this.zhiValue = j
					this.request()
				}
			},
			current(e) {
				this.page = e - 1;
				this.request()
			}
		}
	}
</script>

<style scoped>
	.fenye {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: .2rem 0;
		margin-bottom: 0.5rem;
	}

	.information-content-title {
		margin-bottom: .6rem;
	}

	.item-right-main-bottom span {
		font-size: .14rem;
		color: #666666;
	}

	.item-right-main-top {
		padding-top: .1rem;
		margin-bottom: .16rem;
		font-size: .16rem;
		font-weight: 600;
		color: #333333;
	}

	.item-right-main-top>span {
		font-size: .16rem;
		font-weight: 400;
		color: #666666 !important;
	}

	.item-right-header img {
		width: .76rem;
		border-radius: .38rem;
	}

	.item-right-header {
		margin-right: .18rem;
	}

	.content-main-item-right {
		display: flex;
		padding-top: .18rem;
		padding-left: .6rem;
	}

	.line-main {
		width: 0px;
		height: 1.10rem;
		border-right: 1px dashed #707070;

	}

	.line {
		opacity: .4;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: .1rem;
	}

	.item-xin span {
		font-size: .24rem;
		color: #EA1C1C;
		font-weight: 500;
		position: absolute;
		right: .2rem;
	}

	.item-xin {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.item-informate-di span {
		font-size: .14rem;
		font-weight: 400;
		color: #666666;
	}

	.item-informate-di img {
		margin-right: .06rem;
		width: .13rem;
	}

	.item-informate-di {
		display: flex;
		align-items: center;
	}

	.item-informate>span {
		margin-bottom: .16rem;
		font-size: .22rem;
		font-weight: bold;
	}

	.item-informate {
		width: 7rem;
		display: flex;
		flex-direction: column;
	}

	.content-main-item-left {
		display: flex;
		width: 7rem;
	}

	.item-tag-item2 span {
		display: inline-block;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		transform : scale(0.84,0.84) ;
	}

	.item-tag-item2 img {
		margin-right: .05rem;
		width: .16rem;
	}

	.item-tag-item2 {
		padding-left: .10rem;
		padding-right: .10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: .64rem;
		height: .20rem;
		background: #54EDAC;
	}

	.item-tag-item span {
		display: inline-block;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		transform : scale(0.84,0.84) ;
	}

	.item-tag-item img {
		margin-right: .05rem;
		width: .16rem;
	}

	.item-tag-item {
		padding-left: .10rem;
		padding-right: .10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: .1rem;
		min-width: .64rem;
		height: .20rem;
		background: #5483ED;
	}

	.item-tag {
		display: flex;
		margin-bottom: .24rem;
	}

	.content-main-item {
		display: flex;
		margin-bottom: .48rem;
		padding-top: .15rem;
		padding-left: .16rem;
		box-sizing: border-box;
		width: 12rem;
		height: 1.3rem;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
	}

	.tab-categoryName-content li {
		box-sizing: border-box;
		padding: 0 .1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: .28rem;
		min-width: .73rem;
		height: .35rem;
		border: 1px solid #D3D3D3;
		font-size: .14rem;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}

	.te {
		color: #FFFFFF !important;
		background: #E41D1D;
		border: 0 !important;
	}

	.tab-categoryName-content {
		display: flex;
	}

	.tab-categoryName span {
		margin-right: .1rem;
		font-size: .14rem;
		font-weight: 600;
		color: #333333;
	}

	.tab-categoryName {
		display: flex;
		align-items: center;
		width: 100%;
		height: .9rem;
	}

	.tab-tit li {
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		width: 1rem;
		height: 30px;
		font-size: .16rem;
		font-weight: 600;
		color: #333333;
		cursor: pointer;

	}

	.active {
		border-bottom: 3px solid #E41D1D;
		color: #E41D1D !important;
	}

	.tab-tit {
		box-sizing: border-box;
		width: 100%;
		height: 30px;
		display: flex;
		padding-left: .2rem;
		padding-bottom: 10px;
	}

	.information-content {
		padding: 0 3.6rem;
		font-family: PingFang SC;
	}

	.banners {
		margin-bottom: 1.04rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.banners img {
		width: 12rem;
	}

	.about-main-content>div {
		padding-top: .1rem;
		font-family: PingFang SC;
		font-size: .16prem;
		font-weight: 500;
		line-height: .35rem;
		color: #333333;
	}

	.about-main-content>span {
		font-size: .18rem;
		font-weight: bold;
		color: #333333;
	}

	.about-main-content {
		padding-top: .44rem;
		padding-left: .77rem;
		padding-right: .6rem;
		box-sizing: border-box;
		width: 5.75rem;
		height: 4rem;
		background: #FFFFFF;
		opacity: 0.88;
		border-radius: 6px;
	}

	.about-main {
		display: flex;
		justify-content: center;
		padding-top: 1.5rem;
	}

	.title-right-login {
		display: flex;
		align-items: center;
	}

	.title-right-login img {
		width: .39rem;
	}

	.title-right-login span {
		margin-left: .12rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.title-right {
		margin-left: .65rem;
	}

	.title-left span {
		margin-left: .64rem;
	}

	.tabItem {
		padding: 10px 0;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
		text-decoration: none
	}

	.router-link-active {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #E41D1D;
		border-bottom: .04rem solid #E41D1D;
	}


	.title {
		display: flex;
		align-items: center;
		width: 100%;
		height: .72rem;
		z-index: 999;
		font-size: .16rem;
		font-family: PingFang SC;
		color: #FFFFFF;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(172, 172, 172, 0.1);
		transform: translate3d(0, 0, 0);
	}

	.title-logo {
		margin-left: 3.6rem;
		margin-right: 4.24rem;
	}

	.title-logo img {
		width: 1.5rem;
	}

	img[lazy="error"] {
		display: block;
		width: .64rem !important;
		height: .64rem !important;
		margin: 0 auto;
	}

	img[lazy="loading"] {
		display: block;
		width: .64rem !important;
		height: .64rem !important;
		margin: 0 auto;
	}
</style>
